import { ColorString, makeColor } from "./Color";

export const ClothesIndices = {
    indexHairLight: 129,
    indexHairDark: 172,
    indexHatLight: 185,
    indexHatMid: 191,
    indexHatDark: 197,
    indexShirtLight: 182,
    indexShirtMid: 195,
    indexShirtDark: 202,
    indexSkinLight: 8,
    indexSkinMid: 15,
    indexSkinDark: 57,
    indexPantsLight: 127,
    indexPantsDark: 170,
    indexShoes: 136,
    indexBeltLight: 47,
    indexBeltDark: 89,
    indexAccessoryLight: 210,
    indexAccessoryDark: 213,
    indexWeaponLight: 180,
    indexWeaponDark: 194
}

export const OrderedClothesIndices: Array<keyof typeof ClothesIndices> = [
    "indexHairLight",
    "indexHairDark",
    "indexHatLight",
    "indexHatMid",
    "indexHatDark",
    "indexShirtLight",
    "indexShirtMid",
    "indexShirtDark",
    "indexSkinLight",
    "indexSkinMid",
    "indexSkinDark",
    "indexPantsLight",
    "indexPantsDark",
    "indexShoes",
    "indexBeltLight",
    "indexBeltDark",
    "indexAccessoryLight",
    "indexAccessoryDark",
    "indexWeaponLight",
    "indexWeaponDark"
];

export class Palette {
    // idx * 4 => (r, g, b, a)
    /** The classic Mac palette that's the basis for all CL graphics */
    static readonly classicMacColors: Uint8ClampedArray = new Uint8ClampedArray([
        255, 255, 255, 0, 255, 255, 204, 255, 255, 255, 153, 255, 255, 255, 102, 255,
        255, 255, 51, 255, 255, 255, 0, 255, 255, 204, 255, 255, 255, 204, 204, 255,
        255, 204, 153, 255, 255, 204, 102, 255, 255, 204, 51, 255, 255, 204, 0, 255,
        255, 153, 255, 255, 255, 153, 204, 255, 255, 153, 153, 255, 255, 153, 102, 255,
        255, 153, 51, 255, 255, 153, 0, 255, 255, 102, 255, 255, 255, 102, 204, 255,
        255, 102, 153, 255, 255, 102, 102, 255, 255, 102, 51, 255, 255, 102, 0, 255,
        255, 51, 255, 255, 255, 51, 204, 255, 255, 51, 153, 255, 255, 51, 102, 255,
        255, 51, 51, 255, 255, 51, 0, 255, 255, 0, 255, 255, 255, 0, 204, 255,
        255, 0, 153, 255, 255, 0, 102, 255, 255, 0, 51, 255, 255, 0, 0, 255,
        204, 255, 255, 255, 204, 255, 204, 255, 204, 255, 153, 255, 204, 255, 102, 255,
        204, 255, 51, 255, 204, 255, 0, 255, 204, 204, 255, 255, 204, 204, 204, 255,
        204, 204, 153, 255, 204, 204, 102, 255, 204, 204, 51, 255, 204, 204, 0, 255,
        204, 153, 255, 255, 204, 153, 204, 255, 204, 153, 153, 255, 204, 153, 102, 255,
        204, 153, 51, 255, 204, 153, 0, 255, 204, 102, 255, 255, 204, 102, 204, 255,
        204, 102, 153, 255, 204, 102, 102, 255, 204, 102, 51, 255, 204, 102, 0, 255,
        204, 51, 255, 255, 204, 51, 204, 255, 204, 51, 153, 255, 204, 51, 102, 255,
        204, 51, 51, 255, 204, 51, 0, 255, 204, 0, 255, 255, 204, 0, 204, 255,
        204, 0, 153, 255, 204, 0, 102, 255, 204, 0, 51, 255, 204, 0, 0, 255,
        153, 255, 255, 255, 153, 255, 204, 255, 153, 255, 153, 255, 153, 255, 102, 255,
        153, 255, 51, 255, 153, 255, 0, 255, 153, 204, 255, 255, 153, 204, 204, 255,
        153, 204, 153, 255, 153, 204, 102, 255, 153, 204, 51, 255, 153, 204, 0, 255,
        153, 153, 255, 255, 153, 153, 204, 255, 153, 153, 153, 255, 153, 153, 102, 255,
        153, 153, 51, 255, 153, 153, 0, 255, 153, 102, 255, 255, 153, 102, 204, 255,
        153, 102, 153, 255, 153, 102, 102, 255, 153, 102, 51, 255, 153, 102, 0, 255,
        153, 51, 255, 255, 153, 51, 204, 255, 153, 51, 153, 255, 153, 51, 102, 255,
        153, 51, 51, 255, 153, 51, 0, 255, 153, 0, 255, 255, 153, 0, 204, 255,
        153, 0, 153, 255, 153, 0, 102, 255, 153, 0, 51, 255, 153, 0, 0, 255,
        102, 255, 255, 255, 102, 255, 204, 255, 102, 255, 153, 255, 102, 255, 102, 255,
        102, 255, 51, 255, 102, 255, 0, 255, 102, 204, 255, 255, 102, 204, 204, 255,
        102, 204, 153, 255, 102, 204, 102, 255, 102, 204, 51, 255, 102, 204, 0, 255,
        102, 153, 255, 255, 102, 153, 204, 255, 102, 153, 153, 255, 102, 153, 102, 255,
        102, 153, 51, 255, 102, 153, 0, 255, 102, 102, 255, 255, 102, 102, 204, 255,
        102, 102, 153, 255, 102, 102, 102, 255, 102, 102, 51, 255, 102, 102, 0, 255,
        102, 51, 255, 255, 102, 51, 204, 255, 102, 51, 153, 255, 102, 51, 102, 255,
        102, 51, 51, 255, 102, 51, 0, 255, 102, 0, 255, 255, 102, 0, 204, 255,
        102, 0, 153, 255, 102, 0, 102, 255, 102, 0, 51, 255, 102, 0, 0, 255,
        51, 255, 255, 255, 51, 255, 204, 255, 51, 255, 153, 255, 51, 255, 102, 255,
        51, 255, 51, 255, 51, 255, 0, 255, 51, 204, 255, 255, 51, 204, 204, 255,
        51, 204, 153, 255, 51, 204, 102, 255, 51, 204, 51, 255, 51, 204, 0, 255,
        51, 153, 255, 255, 51, 153, 204, 255, 51, 153, 153, 255, 51, 153, 102, 255,
        51, 153, 51, 255, 51, 153, 0, 255, 51, 102, 255, 255, 51, 102, 204, 255,
        51, 102, 153, 255, 51, 102, 102, 255, 51, 102, 51, 255, 51, 102, 0, 255,
        51, 51, 255, 255, 51, 51, 204, 255, 51, 51, 153, 255, 51, 51, 102, 255,
        51, 51, 51, 255, 51, 51, 0, 255, 51, 0, 255, 255, 51, 0, 204, 255,
        51, 0, 153, 255, 51, 0, 102, 255, 51, 0, 51, 255, 51, 0, 0, 255,
        0, 255, 255, 255, 0, 255, 204, 255, 0, 255, 153, 255, 0, 255, 102, 255,
        0, 255, 51, 255, 0, 255, 0, 255, 0, 204, 255, 255, 0, 204, 204, 255,
        0, 204, 153, 255, 0, 204, 102, 255, 0, 204, 51, 255, 0, 204, 0, 255,
        0, 153, 255, 255, 0, 153, 204, 255, 0, 153, 153, 255, 0, 153, 102, 255,
        0, 153, 51, 255, 0, 153, 0, 255, 0, 102, 255, 255, 0, 102, 204, 255,
        0, 102, 153, 255, 0, 102, 102, 255, 0, 102, 51, 255, 0, 102, 0, 255,
        0, 51, 255, 255, 0, 51, 204, 255, 0, 51, 153, 255, 0, 51, 102, 255,
        0, 51, 51, 255, 0, 51, 0, 255, 0, 0, 255, 255, 0, 0, 204, 255,
        0, 0, 153, 255, 0, 0, 102, 255, 0, 0, 51, 255, 238, 0, 0, 255,
        221, 0, 0, 255, 187, 0, 0, 255, 170, 0, 0, 255, 136, 0, 0, 255,
        119, 0, 0, 255, 85, 0, 0, 255, 68, 0, 0, 255, 34, 0, 0, 255,
        17, 0, 0, 255, 0, 238, 0, 255, 0, 221, 0, 255, 0, 187, 0, 255,
        0, 170, 0, 255, 0, 136, 0, 255, 0, 119, 0, 255, 0, 85, 0, 255,
        0, 68, 0, 255, 0, 34, 0, 255, 0, 17, 0, 255, 0, 0, 238, 255,
        0, 0, 221, 255, 0, 0, 187, 255, 0, 0, 170, 255, 0, 0, 136, 255,
        0, 0, 119, 255, 0, 0, 85, 255, 0, 0, 68, 255, 0, 0, 34, 255,
        0, 0, 17, 255, 238, 238, 238, 255, 222, 222, 222, 255, 187, 187, 187, 255,
        170, 170, 170, 255, 136, 136, 136, 255, 119, 119, 119, 255, 85, 85, 85, 255,
        68, 68, 68, 255, 34, 34, 34, 255, 3, 3, 3, 255, 0, 0, 0, 255,
    ]);

    static readonly classicMacColorsAsInt32 = new Uint32Array(this.classicMacColors.buffer);

    /** Starting default palette (n->n) that gets mutated by clothing */
    static readonly basePalette: Uint8ClampedArray = new Uint8ClampedArray(Array(256).fill(0).map((n, idx) => idx));
}

export function clothesColorToPaletteIndex(colorString: ColorString): number {
    if (colorString === '000') return 255;
    const color = makeColor(colorString);
    return color.paletteIndex;
}